import React from 'react'

import SEO from '../components/SEO/SEO'
import ResumeComponent from '../components/Resume/Resume'
import Transition from '../components/Transition/Transition'
import PageTitle from '../components/PageTitle/PageTitle'

const Resume: React.FC = () => (
    <Transition>
        <SEO title='Resume' />
        <PageTitle title={'Resume'} />
        <ResumeComponent />
    </Transition>
)

export default Resume
