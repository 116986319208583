import React from 'react'
import { Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        titleWrapper: {
            display: 'flex',
            position: 'relative',
            padding: theme.spacing(1),
            color: theme.palette.grey['600'],
            '&:before': {
                content: "''",
                display: 'block',
                flex: '1 1 auto',
                position: 'relative',
                borderTop: `2px solid ${theme.palette.grey['500']}`,
                transform: 'translateY(50%)',
            },
            '&:after': {
                content: "''",
                display: 'block',
                flex: '1 1 auto',
                position: 'relative',
                borderTop: `2px solid ${theme.palette.grey['500']}`,
                transform: 'translateY(50%)',
            },
        },
    })
)

type PageTitleProps = {
    title: string
}

const PageTitle: React.FC<PageTitleProps> = (props: PageTitleProps) => {
    const { title } = props
    const styles = useStyles()
    return (
        <div className={styles.titleWrapper}>
            <Typography
                variant={'h3'}
                component={'h1'}
                align={'center'}
                style={{ margin: '0 16px' }}
            >
                {title}
            </Typography>
        </div>
    )
}

export default PageTitle
