import React from 'react'

import { Paper, PaperProps, Theme, Typography } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        contentSectionBase: {
            overflow: 'hidden',
            '&:nth-child(n+2)': {
                marginTop: theme.spacing(2),
            },
            '& ul, & ol, & p': {
                '&:not(:first-child)': {
                    marginTop: theme.spacing(1),
                },
            },
            '&& ul, && ol': {
                '& ~ p': {
                    marginTop: theme.spacing(2),
                },
            },
        },
        contentSectionContainer: {
            '&:nth-child(n+2)': {
                marginTop: theme.spacing(3),
            },
            '&>$head': {
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.main,
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            },
            '&>$body': {
                padding: theme.spacing(2),
            },
        },

        contentSectionContainerChild: {
            '&>$head': {
                fontWeight: 500,
                color: theme.palette.primary.main,
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                textTransform: 'uppercase',
            },
            '&>$body': {
                padding: theme.spacing(2),
            },
        },

        contentSectionChild: {
            '&>$head': {
                color: theme.palette.primary.main,
                marginBottom: theme.spacing(1),
                // borderBottom: `2px solid ${theme.palette.primary.main}`,
                // padding: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
            },
            '&>$body': {
                // paddingTop: theme.spacing(0.5),
                '&>$contentSectionChild': {
                    padding: `0 ${theme.spacing(2)}`,
                },
            },
        },

        // Classes defined through parents ↑
        head: {},
        body: {},
    })
)

export interface ContentSectionProps extends PaperProps {
    title: string
    container?: boolean
    child?: boolean
    className?: string
}

const ContentSection: React.FC<React.PropsWithChildren<ContentSectionProps>> =
    ({
        title,
        container = false,
        child = false,
        children,
        className,
        ...others
    }: React.PropsWithChildren<ContentSectionProps>) => {
        const styles = useStyles()
        return (
            <Paper
                elevation={container && !child ? 2 : 0}
                className={clsx(
                    styles.contentSectionBase,
                    container && !child && styles.contentSectionContainer,
                    container && child && styles.contentSectionContainerChild,
                    child && !container && styles.contentSectionChild,
                    className
                )}
                {...others}
            >
                <Typography
                    variant={container ? 'h5' : 'h6'}
                    component={container ? 'h2' : 'h3'}
                    className={styles.head}
                >
                    {title}
                </Typography>
                <div className={styles.body}>{children}</div>
            </Paper>
        )
    }

export default ContentSection
