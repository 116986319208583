import React from 'react'
import ContentSection from '../ContentSection/ContentSection'
import ResumeSectionDetails from '../ResumeSectionDetails/ResumeSectionDetail'
import { Typography } from '@mui/material'

const Resume: React.FC = () => {
    return (
        <>
            <ContentSection container title='About'>
                <Typography>
                    Adept at meticulous problem-solving, I approach challenges
                    with enthusiasm and as a seasoned full-stack developer with
                    a focus on front-end, I am driven by an unwavering
                    commitment to refining my skills and embracing emerging
                    technologies across various domains. What defines me is an
                    unrelenting eagerness to learn, allowing me to thrive
                    outside my comfort zone. This readiness to explore the
                    unknown is the cornerstone of my versatility, allowing me to
                    constantly elevate my knowledge and expertise. Adept at
                    meticulous problem-solving, I approach challenges with
                    enthusiasm and as a seasoned full-stack developer with a
                    focus on front-end, I am driven by an unwavering commitment
                    to refining my skills and embracing emerging technologies
                    across various domains. What defines me is an unrelenting
                    eagerness to learn, allowing me to thrive outside my comfort
                    zone. This readiness to explore the unknown is the
                    cornerstone of my versatility, allowing me to constantly
                    elevate my knowledge and expertise.
                    <br />I am also interested and engaged in experimentation
                    with an array of technologies, including Docker, CodeBuild,
                    ECR, Terraform, DynamoDB, Lambda, and the intricacies of
                    Microservices architectures. My focus is centered around
                    cloud architectures and the orchestration of DevOps
                    pipelines. With a specialisation in AWS services, my
                    expertise lies in devising strategies to seamlessly migrate
                    workloads to the cloud. This endeavour aims to enhance
                    scalability, operational efficiency, and carve pathways for
                    future growth.
                </Typography>
            </ContentSection>
            <ContentSection container title='Career Summary'>
                <ResumeSectionDetails
                    title={'Senior Front-end Developer'}
                    where={'Macquarie Bank'}
                    from={'Aug 2022'}
                    to={'Present'}
                    descriptionLabel={'Key tasks'}
                    points={[
                        'Craft high-quality solution designs for front-end tasks',
                        'Provide technical guidance and mentorship to front-end resources within the team',
                        'Conduct thorough front-end code reviews',
                        'Transform UI/UX design wireframes into tangible code that generates the visual elements of applications',
                        'Ensure the technical viability of UI/UX designs',
                        'Collaborate closely with UI/UX designers to bridge the gap between graphical concepts and technical execution',
                        'Develop reusable code snippets and libraries for future implementation',
                        'Refine and convert existing code modules for integration within new CMS environments',
                        'Ensure adherence of user-facing features to established design, coding, security, and legal standards',
                        'Engage in productive collaboration with fellow team members and stakeholders',
                        'Participate in technical discussions, design assessments, and code reviews with the team',
                        'Identify and resolve performance bottlenecks and scalability challenges',
                        'Enhance pre-existing code modules with a primary focus on optimizing for SEO',
                        'Partner with product designers to construct a customer-centric website with a strong emphasis on delivering an exceptional client experience',
                    ]}
                />
                <ResumeSectionDetails
                    title={'UX/UI Designer & Web Developer'}
                    where={'Mwave.com.au'}
                    from={'Mar 2018'}
                    to={'Aug 2022'}
                    descriptionLabel={'Key tasks'}
                    points={[
                        'Streamline workflows through automation',
                        'Scrape data to extract business insights',
                        'Orchestrate the user interaction and flow of the mobile app from scratch',
                        'Enhance the user experience in critical parts on the existing website',
                        "Examine the website's weaknesses and propose effective UX-enhancing solutions",
                        'Conceptualise the design process by crafting user personas, mapping user journeys and flows, and recommend improvements',
                        'Translate design concepts into interactive prototypes using web technologies',
                        'Pioneer research and implementation of technical solutions to expedite internal workflows and increase productivity',
                        'Develop and launch dedicated subdomain websites to drive leads for specific product categories',
                        'Collaborate with the marketing team to deploy refined landing pages for enhanced effectiveness',
                        'Devise and develop ad-hoc components to elevate engagement levels during events',
                        'Ensure responsiveness and adaptability of landing pages across various devices',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    title={'IT Support'}
                    where={'Telorisolvo.com'}
                    from={'2017'}
                    to={'2018'}
                    descriptionLabel={'Key tasks'}
                    points={[
                        'Troubleshoot a range of IT issues experienced by end users through remote assistance',
                        'Provide expertise in sales consultation and advice regarding tech products',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    title={'Android Developer'}
                    where={'Freelance'}
                    from={'2016'}
                    to={'2018'}
                    descriptionLabel={'Key tasks'}
                    points={[
                        'Architect and engineer applications',
                        'Explore and acquire proficiency in various components',
                        'Create custom components',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    title={'IT Support'}
                    where={'Giolitti-Bellisatio Public School'}
                    from={'2016'}
                    to={'2017'}
                    descriptionLabel={'Key tasks'}
                    points={[
                        'Provide IT Technical support for all the school staff',
                        'Maintain workstations and servers',
                        'Configure, deploy and maintain laptops, tablets and other equipment',
                        'Collaborate with third party technicians to deliver special projects',
                        'Manage IT hardware stock levels and inventory',
                        'Build and configure new machines for the staff',
                        'Perform hardware updates',
                        'Check daily and monthly backups',
                        'Ensure all IT equipment is efficient and in good conditions',
                        'Prepare rooms for conferences',
                        'Resolve various IT issues via remote assistance',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    title={'IT Support/Technician - Web developer'}
                    where={'F2M Infotechnology'}
                    from={'2013'}
                    to={'2015'}
                    descriptionLabel={'Key tasks'}
                    points={[
                        'Troubleshoot hardware, software and printers',
                        'Build desktop and laptop computers',
                        'Upgrade hardware and software',
                        'Deploy and configure network infrastructures',
                        'OS installation and recovery operations',
                        'Provide remote assistance',
                        'Execute data recovery procedures',
                        'Undertake websites development',
                        'Provide expertise in sales consultation and advice regarding tech products',
                        'Replace displays for laptops and smartphones',
                        'Repair motherboard components when possible',
                    ]}
                    divider
                />
            </ContentSection>
            <ContentSection container title='Qualifications'>
                <ResumeSectionDetails
                    title={'AWS Certified Solutions Architect - Professional'}
                    description={
                        'Earners of this certification have an extensive understanding of designing technical strategies to accomplish specific business goals. They demonstrated the ability to balance best practices and trade-offs based on business context. Badge owners are able to design solutions across multiple platforms and providers.'
                    }
                    from={'Jan 2022'}
                />
                <ResumeSectionDetails
                    title={'AWS Certified Solutions Architect - Associate'}
                    description={
                        'Earners of this certification have a comprehensive understanding of AWS services and technologies. They demonstrated the ability to build secure and robust solutions using architectural design principles based on customer requirements. Badge owners are able to strategically design well-architected distributed systems that are scalable, resilient, efficient, and fault-tolerant.'
                    }
                    from={'Oct 2021'}
                    divider
                />
                <ResumeSectionDetails
                    title={
                        'Chief qualified industrial technician: IT Specialisation'
                    }
                    from={'2008'}
                    to={'2013'}
                    divider
                />
                <ResumeSectionDetails
                    title={
                        'Cisco CCNA Discovery: Networking for Home and Small Business'
                    }
                    from={'2013'}
                    points={[
                        'Set up a personal computer system, including the operating system, interface cards and peripheral devices',
                        'Plan and install a small business network and connect it to the Internet',
                        'Verify and troubleshoot network and Internet connectivity',
                        'Share resources such as files and printers among multiple computers',
                        'Recognize and mitigate security threats to a home network',
                        'Configure and certify common Internet applications',
                        'Configure basic IP services through a GUI',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    title={'Cisco IT Essentials: PC Hardware and Software'}
                    from={'2011'}
                    points={[
                        'Define Information Technology (IT) and describe the components of a personal computer',
                        'Protect themselves, equipment and the environment from accidents, damage, and contamination',
                        'Perform a systematic assembly of a desktop computer',
                        'Explain the purpose of preventive maintenance and identify the elements of the troubleshooting process',
                        'Install and navigate an operating system',
                        'Upgrade or replace components of a laptop, printer, or scanner based on customer needs',
                        'Configure computers to attach to an existing network',
                        'Apply good communication skills and professional behaviour while working with customers',
                        'Installation of peripherals and multimedia capabilities',
                        'Perform preventive maintenance and basic troubleshooting',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    title={'ECDL Core'}
                    from={'2010'}
                    description={
                        "IT's basic concepts, Use of computer/File Management, Word processing, Spreadsheets, Databases, Presentations, Computer networks - Internet."
                    }
                    divider
                />
            </ContentSection>
            <ContentSection container title='Technical Skills'>
                <ResumeSectionDetails
                    descriptionLabel={false}
                    title={'Frontend technologies'}
                    points={[
                        'HTML / PUG',
                        'CSS / SCSS / CSS-in-JS',
                        'Javascript / Typescript / React / Redux / React Router / MUI',
                        'anime.js / react-spring / Framer Motion',
                        'SEO Optimisations',
                    ]}
                />
                <ResumeSectionDetails
                    descriptionLabel={false}
                    title={'Backend technologies'}
                    points={[
                        'Node.js / Express',
                        'GraphQL',
                        'Puppeteer',
                        'Cheerio.js',
                        'Relational Databases / MySQL',
                        'NoSQL Databases',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    descriptionLabel={false}
                    title={'Other technologies'}
                    points={[
                        'AWS Cloud Services',
                        'Docker',
                        'Terraform',
                        'Elasticsearch',
                        'Webpack',
                        'Serverless Framework',
                        'Git / Versioning systems',
                    ]}
                    divider
                />
                <ResumeSectionDetails
                    descriptionLabel={false}
                    title={'Design Tools'}
                    points={[
                        'Adobe Photoshop / Illustrator / InDesign',
                        'Adobe XD / ProtoPie / Invision',
                    ]}
                    divider
                />
                {/*<ResumeSectionDetails*/}
                {/*    descriptionLabel={false}*/}
                {/*    title={'Android'}*/}
                {/*    points={['Java / Kotlin', 'Android Framework']}*/}
                {/*    divider*/}
                {/*/>*/}
                <ResumeSectionDetails
                    descriptionLabel={false}
                    title={'Learning'}
                    points={['K8S', 'SFMC']}
                    divider
                />
            </ContentSection>
        </>
    )
}

export default Resume
