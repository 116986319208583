import React from 'react'

import { Divider, Theme, Typography, useTheme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            margin: `${theme.spacing(2)} 0`,
        },
        whenWhere: {
            display: 'flex',
            alignItems: 'start',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        marginTop: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(1),
            },
        },
        inlineFlex: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',

            '&&:nth-child(n+2)': {
                marginTop: theme.spacing(1),
                [theme.breakpoints.up('sm')]: {
                    marginTop: 0,
                    marginLeft: theme.spacing(2),
                },
            },
        },
        icon: {
            marginRight: theme.spacing(0.5),
            color: theme.palette.text.secondary,
        },
    })
)

interface ResumeSectionDetailsProps {
    title: string
    where?: string
    from?: string
    to?: string
    description?: string | TemplateStringsArray
    descriptionLabel?: string | false
    points?: (string | TemplateStringsArray)[]
    divider?: boolean
}

const ResumeSectionDetails: React.FC<
    React.PropsWithChildren<ResumeSectionDetailsProps>
> = ({
    title,
    where,
    from,
    to,
    description,
    descriptionLabel = 'Description',
    points,
    divider = false,
}: React.PropsWithChildren<ResumeSectionDetailsProps>) => {
    const styles = useStyles()
    const theme = useTheme()
    return (
        <section>
            {divider && <Divider className={styles.divider} />}
            <Typography variant='h6' component='h3'>
                {title}
            </Typography>
            {(where || from) && (
                <div className={clsx(styles.whenWhere, styles.marginTop)}>
                    {where && (
                        <Typography className={styles.inlineFlex}>
                            <BusinessOutlinedIcon className={styles.icon} />
                            {where}
                        </Typography>
                    )}
                    {from && (
                        <Typography className={styles.inlineFlex}>
                            <DateRangeOutlinedIcon className={styles.icon} />
                            {from}
                            {to && ' - ' + to}
                        </Typography>
                    )}
                </div>
            )}
            {descriptionLabel !== false && (description || points) && (
                <Typography
                    variant={'overline'}
                    component={'div'}
                    className={styles.marginTop}
                    style={{ color: theme.palette.text.secondary }}
                >
                    {descriptionLabel}
                </Typography>
            )}
            {description && (
                <Typography
                    className={clsx(!descriptionLabel && styles.marginTop)}
                >
                    {description}
                </Typography>
            )}
            {points && (
                <ul className={clsx(!descriptionLabel && styles.marginTop)}>
                    {points.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            )}
        </section>
    )
}

export default ResumeSectionDetails
